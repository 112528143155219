import throttle from '../../../utils/throttle'
import location from '../../../utils/location'
import metadata from '../../../utils/metadata'
import store from '../../../components/last-seen-store'

function update (current, position, props) {
  const duration = current.metadata.duration
    ? metadata.parseDurationToSeconds(current.metadata.duration)
    : null

  const item = {
    id: current.id,
    title: current.title,
    serieId: props.serieId,
    images: current.posters,
    position,
    duration,
    url: props.path,
    recommendations: {
      contentId: props.recommendationsContentId,
      name: current.title
    }
  }

  store.update(item)
}

function configure (ludoPlayer, episodes) {
  const size = episodes.length
  const lut = {}
  for (let i = 0; i < size; i++) {
    lut[episodes[i].id] = i
  }

  const callback =
    () => {
      const current = ludoPlayer.current()
      const position = ludoPlayer.currentTime()
      const episode = episodes[lut[current.id]]

      const props = {
        seriesId: episode.seriesId,
        path: location.path(),
        recommendationsContentId: episode._embedded.ga.dimension1
      }

      update(current, position, props)
    }

  ludoPlayer.on('timeupdate', throttle(callback, 5000))
  ludoPlayer.on('seeked', callback)
}

export default {
  configure
}
