import '../../polyfills'
import shelfsPanel from '../../components/shelfs/panel'
import ludoPanel from '../../components/ludo/panel'
import indexpointsPanel from '../../components/indexpoints/panel'
import seasonsPanel from '../../components/seasons/panel'
import recommendations from '../../components/recommendations'
import episodes from './episodes'
import coreToggle from '@nrk/core-toggle'

function configureRecommendations (ludoPlayer) {
  const element = document.querySelector('.series[data-recommendations-content-id]')
  if (!element) {
    return
  }

  const contentId = element.getAttribute('data-recommendations-content-id')
  const contentGroupId = element.getAttribute('data-recommendations-content-group-id') || null
  recommendations.configurePlayerContent(ludoPlayer, contentId, contentGroupId)
}

document.addEventListener('DOMContentLoaded', event => {
  shelfsPanel.load()

  const ludoPlayer = ludoPanel.loadFromElement(
    document.querySelector('div.ludo-element-wrapper')
  )
  indexpointsPanel.load(ludoPlayer)

  seasonsPanel.load()
  episodes.load(ludoPlayer, indexpointsPanel)

  configureRecommendations(ludoPlayer)
  coreToggle('.programinfo-panel .sup-core-toggle')
  window.ludoPlayer = ludoPlayer
})
