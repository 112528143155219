function throttle (callback, timeout) {
  let wait = false
  return () => {
    if (!wait) {
      wait = true
      setTimeout(() => {
        callback()
        wait = false
      }, timeout)
    }
  }
}

export default throttle
