const convertToSrcSet = require('../../utils/convert-to-src-set')

function createEpisodeImageHtml (episode) {
  return `<img class="lazyload" data-src="${episode.image[1].url}" data-srcset="${convertToSrcSet(episode.image)}" sizes="(min-width: 720px) 25vw, 100vw" />`
}

function createEpisodeFigcaptionHtml (episode) {
  return `<figcaption data-episode-figure>${episode.titles.title}</figcaption>`
}

function createEpisodeFigureHtml (episode) {
  return `<figure data-episode-figure><div class="nrk-aspect lazyload-container-light">${createEpisodeImageHtml(episode)}</div>${createEpisodeFigcaptionHtml(episode)}</figure>`
}

function createEpisodeHtml (episode, activeEpisodeId) {
  const attrs = episode.id === activeEpisodeId
    ? ` class="sub-db episode active-episode" data-episode-id="${episode.id}" data-episode-url="${episode.url}"`
    : ` class="sub-db episode" href="${episode.url}" data-episode-id="${episode.id}" data-episode-url="${episode.url}"`

  return `<div class="nrk-xs-12of12 nrk-md-3of12 sup-prl sup-sm-prn"><a${attrs}>${createEpisodeFigureHtml(episode)}</a></div>`
}

function createEpisodesHtml (episodes, activeEpisodeId) {
  return `<div class="nrk-grid">${episodes.map(episode => createEpisodeHtml(episode, activeEpisodeId)).join('')}</div>`
}

module.exports = createEpisodesHtml
