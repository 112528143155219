import rafThrottle from '../../utils/raf-throttle'

function resizeListener (needUpdateCallback) {
  rafThrottle('resize', 'optimizedResize')

  // These two magic numbers are breakpoints where the maxHeight changes in the CSS and the visibility of the season
  // expander must be re-calculated in case it now should be shown or hidden.
  const screenBreakThresholds = [720, 1180]
  let lastWindowWidth = window.innerWidth
  function handleResize () {
    const currentWidth = window.innerWidth
    const needUpdate = screenBreakThresholds.some(screenBreakThreshold => {
      const passedBelowThreshold = lastWindowWidth >= screenBreakThreshold && currentWidth <= screenBreakThreshold
      const passedAboveThreshold = lastWindowWidth <= screenBreakThreshold && currentWidth >= screenBreakThreshold

      return passedAboveThreshold || passedBelowThreshold
    })

    if (needUpdate) {
      needUpdateCallback()
    }

    lastWindowWidth = currentWidth
  }

  window.addEventListener('optimizedResize', handleResize)
}

export default resizeListener
