/*
 NOTE: The addPlayClickHandler is able to handle click from the items that are cue'ed
       uncomment if we want this feature
*/
// TODO: Store to lastSeenService.
import fetchWrapper from '../../../utils/fetch-wrapper'
import location from '../../../utils/location'
import lastSeenService from './last-seen-service'
import coreToggle from '@nrk/core-toggle'

/**
 * @param {Element} element
 */
function assertElement (element) {
  if (!element) {
    throw new Error('No element for selector(div.series)')
  }

  if (!element.hasAttribute('data-series-id')) {
    throw new Error('Element is missing attribute(data-series-id)')
  }

  if (!element.hasAttribute('data-episode-id')) {
    throw new Error('Element is missing attribute(data-episode-id)')
  }
}

function loadNextEpisodes (seriesId, seasonId, startFromEpisodeId, props) {
  const path = `/serie/${seriesId}/season/${seasonId}/episodes`
  const url = props.prefer && props.prefer !== ''
    ? `${path}?startFromEpisodeId=${startFromEpisodeId}&bruk=${props.prefer}`
    : `${path}?startFromEpisodeId=${startFromEpisodeId}`

  return fetchWrapper.json(url)
}

function updateActiveEpisode (episodeId) {
  const oldNode = document.querySelector('a.active-episode')
  oldNode.setAttribute('href', oldNode.getAttribute('data-episode-url'))
  oldNode.classList.remove('active-episode')

  const activeNode = document.querySelector(`a.episode[data-episode-id=${episodeId}]`)
  activeNode.removeAttribute('href')
  activeNode.classList.add('active-episode')
}

function loadEpisodeProgramInfoPanel (episodeId) {
  fetchWrapper.text(`/program/${episodeId}/info`)
    .then(
      html => {
        const oldElement = document.querySelector('.programinfo-panel')
        oldElement.insertAdjacentHTML('beforebegin', html)
        oldElement.remove()

        coreToggle('.programinfo-panel .sup-core-toggle')
      }
    )
}

function loadEpisodeIndexpointsPanel (episodeId, onIndexpointsUpdatedCallback) {
  fetchWrapper.text(`/program/${episodeId}/indexpoints`)
    .then(
      html => {
        const oldElement = document.querySelector('.indexpoints-panel')
        oldElement.insertAdjacentHTML('beforebegin', html)
        oldElement.remove()
        onIndexpointsUpdatedCallback()
      }
    )
}

function load (ludoPlayer, indexpointsPanel) {
  /** @type {Element} */
  const element = document.querySelector('div.series')
  assertElement(element)

  const seriesId = element.getAttribute('data-series-id')
  const seasonId = element.getAttribute('data-season-id')
  const prefer = document.querySelector('div.ludo-element-wrapper')
    .getAttribute('data-ludo-prefer')

  const activeEpisodeId = element.getAttribute('data-episode-id')
  const indexpointsPanelReset =
    () => indexpointsPanel.reset()

  let windowHistoryChanged = false

  // const addPlayClickHandler =
  //   (element, index) => {
  //     element.addEventListener('click', (event) => {
  //       event.preventDefault()
  //       ludoPlayer.playAt(index)
  //     })
  //   }

  loadNextEpisodes(seriesId, seasonId, activeEpisodeId, { prefer })
    .then(episodes => {
      const programIds = episodes.map(episode => {
        const programId = {
          id: episode.id
        }

        if (prefer !== '') {
          programId[prefer] = true
        }

        return programId
      })
      const size = episodes.length
      const lut = {}
      for (let i = 0; i < size; i++) {
        lut[episodes[i].id] = i
      }

      let index = 0
      ludoPlayer.cue(programIds)
      ludoPlayer.on('ended', () => {
        if (index < size) {
          ludoPlayer.next()
          index++
        }
      })

      // for (let i = 0; i < size; i++) {
      //   const element = document.querySelector(`a.episode[data-episode-id="${episodes[i].id}"]`)
      //   if (element) {
      //     element.addEventListener('click', addPlayClickHandler(element, i))
      //   }
      // }

      let currentEpisodeId = activeEpisodeId
      ludoPlayer.on('play', () => {
        const id = ludoPlayer.current().id
        const episode = episodes[lut[id]]

        /*
          Current path is not the same as the episode url
          ensure to push state with episode url.

          This happens to be when when episode is the active episode for the series path.
        */
        const path = location.path()
        if (path !== episode.url) {
          window.history.pushState({}, '', episode.url)
        }

        // current playing episode is already loaded early return
        if (episode.id === currentEpisodeId) {
          return
        }

        updateActiveEpisode(episode.id)
        loadEpisodeIndexpointsPanel(episode.id, indexpointsPanelReset)
        loadEpisodeProgramInfoPanel(episode.id)

        window.history.pushState({}, '', episode.url)
        windowHistoryChanged = true
        currentEpisodeId = episode.id
      })

      lastSeenService.configure(ludoPlayer, episodes)
    })

  loadEpisodeIndexpointsPanel(activeEpisodeId, indexpointsPanelReset)
  window.ludoPlayer = ludoPlayer

  // to make back button actually work after pushState
  window.onpopstate = (event) => {
    if (windowHistoryChanged) {
      window.location = event.target.location
    }
  }
}

export default {
  load
}
