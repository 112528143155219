// Throttle high frequency events via requestAnimationFrame to improve performance.
module.exports = function (type, name, obj) {
  obj = obj || window
  let running = false
  const func = function () {
    if (running) { return }
    running = true
    window.requestAnimationFrame(function () {
      obj.dispatchEvent(new window.CustomEvent(name))
      running = false
    })
  }
  obj.addEventListener(type, func)
}
