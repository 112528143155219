function getExtraPixelsPerExpand (seasonElem) {
  const figure = seasonElem.querySelector('[data-episode-figure]') || {}
  const fallbackFigureHeight = 200

  return (figure.offsetHeight || fallbackFigureHeight) * 4
}

function getSeasonExpanderElem (seasonElem) {
  return seasonElem.querySelector('[data-season-expander]')
}

function untilMaxHeightReached (seasonElem) {
  const element = seasonElem.querySelector('[data-episode-content]')
  const actualHeight = element.scrollHeight
  const visibleHeight = element.clientHeight

  return actualHeight - visibleHeight
}

function isOverflown (seasonElem) {
  return untilMaxHeightReached(seasonElem) > 5 // IE 10 reports 1px off, give some slack
}

function updateVisibility (seasonElem) {
  const seasonExpanderElem = getSeasonExpanderElem(seasonElem)
  const showExpander = isOverflown(seasonElem)
  seasonExpanderElem.classList[showExpander ? 'remove' : 'add']('sup-dn')
}

function load (seasonElem) {
  const seasonExpanderElem = getSeasonExpanderElem(seasonElem)
  const episodeContentElem = seasonElem.querySelector('[data-episode-content]')
  const miniExpandThreshold = 200

  seasonExpanderElem.addEventListener('click', evt => {
    evt.preventDefault()
    const currentHeight = episodeContentElem.offsetHeight
    let extraPixels = getExtraPixelsPerExpand(seasonElem)

    // Avoids required "mini-expands" by expanding extra when there is max 200 additional pixels left.
    if ((untilMaxHeightReached(seasonElem) - extraPixels) <= miniExpandThreshold) {
      extraPixels += miniExpandThreshold
    }

    episodeContentElem.style.maxHeight = `${currentHeight + extraPixels}px`

    // Fallback of browsers that don't fire transitionend
    setTimeout(() => updateVisibility(seasonElem), 200)
    setTimeout(() => updateVisibility(seasonElem), 500)
  })

  updateVisibility(seasonElem)
  episodeContentElem.addEventListener('transitionend', () => updateVisibility(seasonElem), false)
}

export default {
  updateVisibility,
  load
}
