function load (element, ids, params = {}) {
  if (!window.ludo) {
    throw new Error('Ludo player is unavailable')
  }

  const programIds = Array.isArray(ids) ? ids.concat() : [ids]
  const options = {
    autoplay: params.autoplay || false,
    config: 'simple',
    analytics: {
      ga: {
        trackingId: params.trackingId || 'UA-3413696-44'
      }
    },
    chromecast: {
      enabled: true,
      receiverApplicationId: 'A2CDA0D6'
    },
    apiClient: {
      domain: params.apiClientDomain || 'psapi.nrk.no'
    }
  }

  const ludoPlayer = window.ludo(element, programIds, options)
  let size = programIds.length
  if (size > 1) {
    ludoPlayer.on('ended', () => {
      if (size > 1) {
        size--
        ludoPlayer.next()
      }
    })
  }

  return ludoPlayer
}

function loadFromElement (element) {
  if (!element) {
    throw new Error(`Expected an element received: <${element}>)`)
  }

  if (
    !element.hasAttribute('data-ludo-media-id') ||
    !element.getAttribute('data-ludo-media-id')
  ) {
    throw new Error('Ludo element wrapper is missing mediaId to play')
  }

  if (
    !element.hasAttribute('data-analytics-ga') ||
    !element.getAttribute('data-analytics-ga')
  ) {
    throw new Error('Ludo element wrapper is missing analytics ga value')
  }

  const mediaId = element.getAttribute('data-ludo-media-id')
  const trackingId = element.getAttribute('data-analytics-ga')
  const apiClientDomain = element.getAttribute('data-ludo-apidomain') || ''
  const prefer = element.getAttribute('data-ludo-prefer') || ''

  const options = {
    trackingId,
    apiClientDomain
  }

  const programId = {
    id: mediaId
  }

  if (prefer !== '') {
    programId[prefer] = true
  }

  return load(element, programId, options)
}

export default {
  load,
  loadFromElement
}
