const pattern =
  new RegExp(
    // starts with PT
    '^PT' +
    // one or more digits followed by the hours designator H
    '((\\d+)H){0,1}' +
    // one or more digits followed by the minutes designator M
    '((\\d+)M){0,1}' +
    // one or more digits with precission followed by the seconds designator S
    '((\\d+(\\.\\d+){0,1})S){0,1}' +
    // end
    '$'
  )

function parseDuration (duration) {
  if (!pattern.test(duration)) {
    return null
  }

  const match = pattern.exec(duration)
  return [
    {
      name: 'hours',
      index: 2
    },
    {
      name: 'minutes',
      index: 4
    },
    {
      name: 'seconds',
      index: 6
    }
  ].reduce(
    (acc, item) => {
      // if the match contains a result on the given index then a value has been set
      // otherwise default to zero.
      acc[item.name] = match[item.index]
        ? Number(match[item.index])
        : 0

      return acc
    },
    {}
  )
}

function parseDurationToSeconds (duration) {
  const values = parseDuration(duration)
  return (values.hours * 3600) + (values.minutes * 60) + values.seconds
}

module.exports = {
  parseDuration,
  parseDurationToSeconds
}
