function fetch (...args) {
  return window.fetch(...args).then(response => {
    if (response.status >= 200 && response.status < 300) {
      return response
    }
    const error = new Error(response.statusText)
    error.response = response
    throw error
  })
}

function json (...args) {
  return fetch(...args).then(res => res.json())
}

function text (...args) {
  return fetch(...args).then(res => res.text())
}

export default {
  json,
  text
}
