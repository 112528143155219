import nodeListToArray from '../../utils/nodelist-to-array'

let ludoPlayerRef
let startPointElements = []

function handleClickStartPoint (evt) {
  evt.preventDefault()
  if (ludoPlayerRef.isPaused()) {
    ludoPlayerRef.play()
  }
  const startPoint = evt.currentTarget.getAttribute('data-indexpoint-startpoint')
  ludoPlayerRef.seekTo(Number(startPoint))
}

function load (ludoPlayer) {
  ludoPlayerRef = ludoPlayer
  reset()
}

function reset () {
  // remove existing listeners
  if (startPointElements.length) {
    for (let i = 0, l = startPointElements.length; i < l; i++) {
      startPointElements[i].removeEventListener('click', handleClickStartPoint)
    }
  }

  // add new listeners
  startPointElements = nodeListToArray(document.querySelectorAll('[data-indexpoints] [data-indexpoint-startpoint]'))
  for (let i = 0, l = startPointElements.length; i < l; i++) {
    startPointElements[i].addEventListener('click', handleClickStartPoint)
  }
}

export default {
  load,
  reset
}
