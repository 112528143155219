import nodelistToArray from '../../utils/nodelist-to-array'
import fetchWrapper from '../../utils/fetch-wrapper'
import createEpisodesHtml from './create-episodes-html'
import seasonExpanderService from './season-expander-service'
import resizeListener from './resize-listener'

/**
 * @param {Element} element
 */
function assertElement (element) {
  if (!element) {
    throw new Error('No element for selector(div.series)')
  }

  if (!element.hasAttribute('data-series-id')) {
    throw new Error('Element is missing attribut(data-series-id)')
  }
}

/**
 * @param {string} seriesId
 * @param {string} activeEpisodeId
 * @param {Element} seasonElement
 */
function loadSeason (seasonElement, props) {
  if (seasonElement.getAttribute('data-loaded')) {
    return Promise.resolve()
  }
  seasonElement.setAttribute('data-loaded', '1')

  const seasonId = seasonElement.getAttribute('data-season-id')

  const path = `/serie/${props.seriesId}/season/${seasonId}/episodes`
  const url = props.prefer && props.prefer !== ''
    ? `${path}?bruk=${props.prefer}`
    : path

  return fetchWrapper.json(url)
    .then(
      episodes => {
        const contentNode = seasonElement.querySelector('.episode-content')
        const html = createEpisodesHtml(episodes, props.activeEpisodeId)
        contentNode.insertAdjacentHTML('beforeend', html)
        seasonExpanderService.load(seasonElement)

        contentNode.removeChild(contentNode.querySelector('.sup-tac'))
      }
    )
    .catch(
      () => {
        seasonElement.querySelector('[data-episode-fetch-error]').classList.remove('sup-dn')
        seasonElement.querySelector('[data-episode-loader]').classList.add('sup-dn')
      }
    )
}

function load () {
  /** @type {Element} */
  const element = document.querySelector('.seasons-panel')
  assertElement(element)

  const seriesId = element.getAttribute('data-series-id')
  const activeEpisodeId = element.getAttribute('data-active-episode-id') || ''
  const prefer = element.getAttribute('data-prefer') || ''

  /** @type {Element[]} */
  const seasonElements = nodelistToArray(
    element.querySelectorAll('.seasons-panel .season')
  )

  if (!seasonElements.length) {
    return
  }

  let index = 0
  const total = seasonElements.length
  const size = total < 2
    ? total
    : 2

  let totalLoadedSeasons = 0
  const promises = []
  for (index = 0; index < size; index++) {
    promises.push(
      loadSeason(seasonElements[index], { seriesId, activeEpisodeId, prefer })
    )
    totalLoadedSeasons++
  }

  const loadSeasonsBelowTheScroll = () => {
    if (totalLoadedSeasons >= total) {
      // Finished loading all seasons. Clean up listener
      window.removeEventListener('scroll', handleScroll)
      return Promise.resolve()
    }

    const seasonElement = seasonElements[totalLoadedSeasons]
    const numPixelsBelowTheScroll = 200
    const positionY = seasonElement.offsetTop
    const belowTheScroll = window.pageYOffset > (positionY - numPixelsBelowTheScroll - window.innerHeight)

    // element is not below the scroll
    if (!belowTheScroll) {
      return Promise.resolve()
    }

    return loadSeason(seasonElement, { seriesId, activeEpisodeId, prefer })
      .then(() => {
        totalLoadedSeasons++
        return loadSeasonsBelowTheScroll()
      })
  }

  let scrollTicking = false
  const handleScroll = () => {
    if (!scrollTicking) {
      window.requestAnimationFrame(() => {
        loadSeasonsBelowTheScroll().then(() => {
          scrollTicking = false
        })
      })

      scrollTicking = true
    }
  }

  const initScrollListener = () => {
    window.addEventListener('scroll', handleScroll)
  }

  const initResizeListener = () => {
    resizeListener(() => {
      seasonElements.forEach(seasonElement => seasonExpanderService.updateVisibility(seasonElement))
    })
  }

  Promise.all(promises)
    .then(() => {
      loadSeasonsBelowTheScroll()
      initScrollListener()
      initResizeListener()
    })
}

export default {
  load
}
